@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// Import PrimeNG styles first to ensure they are applied before other styles
@import "primeng/resources/primeng.min.css";

// Import Tailwind CSS
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@layer primeng {
}
@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
}

@layer tailwind-utilities {
  @tailwind utilities;
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@use "@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$client-primary: mat.define-palette(mat.$indigo-palette);
//$client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
//$client-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
//$client-theme: mat.define-light-theme(
//  (
//    color: (
//      primary: $client-primary,
//      accent: $client-accent,
//      warn: $client-warn,
//    ),
//  )
//);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($client-theme);

@import url("/node_modules/flag-icons/css/flag-icons.min.css");
@import "material-symbols";
@import "/node_modules/flag-icons/css/flag-icons.min.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";

// Google Symbols settings
body {
  font-variation-settings:
    "FILL" 0,
    "wght" 500,
    "GRAD" 0,
    "opsz" 48;
  caret-color: transparent;
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
}

input {
  caret-color: black;
}

.dark input {
  caret-color: white;
}

/* styles.css or your main CSS file */
::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

/* Light Mode */
::-webkit-scrollbar-track {
  @apply bg-gray-100; /* background of the scrollbar track in light mode */
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded; /* style for the scrollbar handle in light mode */

  &:hover {
    @apply bg-gray-200; /* style for the scrollbar handle on hover in light mode */
  }
}

/* Dark Mode */
.dark ::-webkit-scrollbar-track {
  @apply bg-gray-700; /* background of the scrollbar track in dark mode */
}

.dark ::-webkit-scrollbar-thumb {
  @apply bg-gray-600 rounded; /* style for the scrollbar handle in dark mode */

  &:hover {
    @apply bg-gray-500; /* style for the scrollbar handle on hover in dark mode */
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
